img.logo {
    height: 40px;
}

.btn-purple {
  @include btn-variant($purple, white, $purple);
  &:hover {
    background-color: $purple !important;
    color: $white !important;
  }
}

.btn-outline-purple {
  @include btn-outline-variant($purple, white);
  &:hover {
    background-color: $purple !important;
    color: $white !important;
  }
}

.alert-purple {
    color: #23132B;
    background-color: rgba(87, 46, 106, 0.2);
    border-color: rgba(87, 46, 106, 1);
}
.badge-purple {
    color: #572E6A !important;
    background-color: rgba(87, 46, 106, 0.2) !important;
}
.badge-light-purple {
    color: #572E6A !important;
    background-color: rgba(87, 46, 106, 0.2) !important;
    border-color: #572E6A !important;
}

option[data-error="true"] {
    color: #dd3535;
}

ol.breadcrumb {
    margin: 0px !important;
}
li.breadcrumb-item {
    font-size: 1.08rem !important;
    letter-spacing: -0.4px;
}

.bidPlacer .accordion-body table tbody tr td.prevDemands {
    overflow: auto;
    width: 12%;
    min-width: 105px;
    max-width: 110px;
}

.bidPlacer table tr td:nth-child(1) {
    min-width: 75px;
    max-width: 90px;
}

.bidPlacer table tr td:nth-child(2) {
  min-width: 60px;
  max-width: 80px;
}
.bidPlacer table tr td:nth-child(3) {
  min-width: 45px;
  max-width: 60px;
}
.bidPlacer table tr td:nth-child(4), .bidPlacer table tr td:nth-child(5) {
  width: 10%;
  min-width: 95px;
  max-width: 120px;
}
.bidPlacer table tr td:nth-child(9) {
    min-width: 320px;
}

.prevDemand {
    margin-right: 0.3rem;
    display: inline-block;
}

.bg-info {
    background-color: rgb(217, 230, 228) !important;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.show.tooltip {
    z-index: 2000 !important;
}
.auction-cp {
    text-align: center;
    width: 90%;
    min-width: 300px;
    max-width: 600px;
    margin: 60px auto;
}
.sr-only {
    display: none;
}
.bidsCP {
    .row {
        dl {
            margin-bottom: 0.6rem;
            dt {
                font-size: 0.875rem !important;
            }
        }
    }
}
.bidPlacer {
    .accordion-body {
        padding: 0;
        overflow-x: scroll;
        table {
            thead {
                tr th {
                    font-size: 0.75rem;
                    text-align: left;
                    font-weight: normal;
                }
                .current-bids-column {
                    min-width: 200px;
                }
            }
            tbody {
                tr td {
                    font-size: 0.9rem;
                    text-align: left;
                }
            }
        }
    }
}

.auctionSummaryCard {
    height: 300px;
    @include media-breakpoint-up(lg) {
        height: 380px;
    }
}
.biddersListGroup {
    height: 110px;
    @include media-breakpoint-up(lg) {
        height: 230px;
    }
    @include media-breakpoint-down(lg) {
        .list-group-item {
            padding: 0.25rem 0.5rem;
        }
    }
}
.selectedBidderHeaderInfo {
    max-height: 28px;
}
@include media-breakpoint-down(lg) {
    .selectedBidderHeaderInfo h6 small {
        font-size: 0.72rem;
    }
}
.selectedAutoBidderInitPkgsListGroup {
    max-height: 90px;
    @include media-breakpoint-up(lg) {
        max-height: 162px;
    }
    @include media-breakpoint-down(lg) {
        .list-group-item {
            padding: 0.25rem 0.5rem;
        }
    }
}
.auctionsList {
    .card-header {
        .row {
            &:hover {
                cursor: pointer;
            }
        }
    }
}
.auctionListItem {
    &:hover {
        cursor: pointer;
        background-color: rgb(232, 232, 232);
    }
    &.selected {
        background-color: rgb(240, 240, 240);
        font-weight: bold;
    }
}

.auctionSummary {
    dl {
        margin-bottom: 0.6rem;
        dt {
            font-size: 0.875rem !important;
        }
    }
}
.regions-picker {
    .col {
        max-height: 600px;
        height: 60vh;
        min-height: 250px;
        overflow-y: scroll;
    }
}
.form-check-label {
    font-size: 0.875rem !important;
}
.sorting_desc::after {
    content: ' ▲';
}
.sorting_asc::after {
    content: ' ▼';
}
.noneParamSelect:hover {
    background-color: #f8d7da !important;
}
.variantSelectableParam:hover {
    background-color: rgba(13, 110, 253,0.3);
    cursor: pointer;
}
.hide-overflow-grid-cell {
    overflow-x: auto;
}