// Override default variables before the import

$primary: #172b6f;
$danger: #C14246;
$info: #973065;
$secondary: #2d4f6f;
$success: #367F4D;
$warning: #AC9851;
$purple: #572E6A;

// Generating @mixin based on 'btn-variant'
@mixin btn-variant ($color1, $color2, $color-hover: color-yiq($color1)) {
  background-color: $color1;
  border-color: $color1;
  color:$color2;
}

//  Generating @mixin based on 'btn-outline-variant'
@mixin btn-outline-variant($color1, $color2) {
  background-color: $color2;
  border-color: $color1;
  color:$color1;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import './css/custom.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
